<template>
	<div class="section">
		<h3 class="heading-3-b mb-20">회사 정보</h3>
		<div class="contents group column gap20">
			<div class="group row col2 gap40 align-start">
				<div
					class="group-inp"
					:class="[
						companyInfoValidClass.companyName == 'error' || companyInfoValidClass.companyName == 'empty'
							? 'invalid'
							: companyInfoValidClass.companyName == 'valid'
							? 'valid'
							: '',
					]"
				>
					<label for="target" class="label asterisk">회사명</label>
					<input
						class=""
						type="text"
						placeholder="회사명"
						@input="
							[
								checkInputLength($event, 2, 64)
									? (companyInfoValidClass.companyName = 'valid')
									: (companyInfoValidClass.companyName = 'error'),
							]
						"
						v-model="companyInfo.companyName"
					/>
					<p v-if="companyInfoValidClass.companyName == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						회사명은 2자 이상, 64자 이하를 입력해야 합니다.
					</p>
					<p v-if="companyInfoValidClass.companyName == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						회사명은 필수 입력 항목입니다.
					</p>
				</div>
				<div
					class="group-inp"
					:class="[
						companyInfoValidClass.employeeCount == 'error' || companyInfoValidClass.employeeCount == 'empty'
							? 'invalid'
							: companyInfoValidClass.employeeCount == 'valid'
							? 'valid'
							: '',
					]"
				>
					<label for="target" class="label asterisk">직원수</label>
					<input
						class=""
						type="text"
						min="0"
						placeholder="직원수"
						id="target"
						v-model="companyInfo.employeeCount"
						v-doms-inputValidate:onlyNumber=""
						@keyup="
							[
								parseInt(companyInfo.employeeCount.length) < 1 || companyInfo.employeeCount <= 0
									? (companyInfoValidClass.employeeCount = 'error')
									: (companyInfoValidClass.employeeCount = 'valid'),
							]
						"
					/>
					<p v-if="companyInfoValidClass.employeeCount == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						직원수를 입력해주세요. (0이상의 숫자)
					</p>
					<p v-if="companyInfoValidClass.employeeCount == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						직원수는 필수 입력 항목입니다.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validation } from '@/components/apply/mixinsValidation.js';
export default {
	mixins: [validation],
	created() {
		this.companyInfo.companyName = this.companyInfo.companyName || this.propsCompany?.corporateName;
		this.companyInfo.employeeCount = this.companyInfo.employeeCount || this.propsCompany?.employeeCount;
	},
	data() {
		const origin = this.$store.getters.getEstimateAllCompanyInfo.companyInfo;
		return {
			companyInfo: {
				companyName: origin.companyName ? origin.companyName : '',
				employeeCount: origin.employeeCount ? origin.employeeCount : '',
			},
			companyInfoValidClass: {
				companyName: origin.employeeCount ? 'valid' : '',
				employeeCount: origin.employeeCount ? 'valid' : '',
			},
		};
	},
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
		propsCompany: {
			type: Object,
		},
	},
	watch: {
		'companyInfo.employeeCount'() {
			if (isNaN(this.companyInfo.employeeCount)) {
				this.companyInfo.employeeCount = '';
			}
		},
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				this.sendValidInfo();
			}
		},
	},
	methods: {
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companyInfo)) {
				const key = Object.keys(this.companyInfo)[idx];
				const value = this.companyInfo[key];

				if (!value || value.length == 0) {
					this.companyInfoValidClass[key] = 'empty';
					isAllValid = false;
				}
				if (this.companyInfoValidClass[key] !== 'valid') {
					isAllValid = false;
				}
			}

			return isAllValid;
		},
		async sendValidInfo() {
			this.checkStrLength(this.companyInfo.companyName, 2, 64)
				? (this.companyInfoValidClass.companyName = 'valid')
				: (this.companyInfoValidClass.companyName = 'error');
			parseInt(this.companyInfo.employeeCount.length) < 1 || this.companyInfo.employeeCount <= 0
				? (this.companyInfoValidClass.employeeCount = 'error')
				: (this.companyInfoValidClass.employeeCount = 'valid');

			this.$emit('gatherData', {
				type: 'companyInfo',
				companyInfo: this.companyInfo,
				companyInfoValidClass: this.companyInfoValidClass,
			});
		},
	},
};
</script>

<style></style>
