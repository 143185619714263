var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('h3',{staticClass:"heading-3-b mb-20"},[_vm._v("담당자 정보")]),_c('div',{staticClass:"contents group column gap20"},[_c('div',{staticClass:"group row col2 gap40 align-start"},[_c('div',{staticClass:"group-inp",class:[
					_vm.companyManagerInfoValidClass.name == 'error' || _vm.companyManagerInfoValidClass.name == 'empty'
						? 'invalid'
						: _vm.companyManagerInfoValidClass.name == 'valid'
						? 'valid'
						: '',
				]},[_c('label',{staticClass:"label asterisk",attrs:{"for":""}},[_vm._v(" 담당자명 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.name),expression:"companyManagerInfo.name"}],attrs:{"type":"text","placeholder":"담당자명"},domProps:{"value":(_vm.companyManagerInfo.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "name", $event.target.value)},function($event){[
							_vm.checkInputLength($event, 2, 64)
								? (_vm.companyManagerInfoValidClass.name = 'valid')
								: (_vm.companyManagerInfoValidClass.name = 'error'),
						]}]}}),(_vm.companyManagerInfoValidClass.name == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 담당자명은 2자 이상, 64자 이하를 입력해야 합니다. ")]):_vm._e(),(_vm.companyManagerInfoValidClass.name == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 담당자명은 필수 입력 항목입니다. ")]):_vm._e()]),_c('div',{staticClass:"group-inp",class:[
					_vm.companyManagerInfoValidClass.email == 'error' || _vm.companyManagerInfoValidClass.email == 'empty'
						? 'invalid'
						: _vm.companyManagerInfoValidClass.email == 'valid'
						? 'valid'
						: '',
				]},[_c('label',{staticClass:"label asterisk",attrs:{"for":""}},[_vm._v("이메일")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.email),expression:"companyManagerInfo.email"}],attrs:{"type":"text","placeholder":"이메일"},domProps:{"value":(_vm.companyManagerInfo.email)},on:{"keyup":function($event){[
							_vm.checkEmail(_vm.companyManagerInfo.email)
								? (_vm.companyManagerInfoValidClass.email = 'valid')
								: (_vm.companyManagerInfoValidClass.email = 'error'),
						]},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "email", $event.target.value)}}}),(_vm.companyManagerInfoValidClass.email == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 이메일 형식이 올바르지 않습니다. ")]):_vm._e(),(_vm.companyManagerInfoValidClass.email == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 담당자 이메일은 필수 입력 항목입니다. ")]):_vm._e()])]),_c('div',{staticClass:"group row col1 half"},[_c('div',{staticClass:"group-inp",class:[
					_vm.companyManagerInfoValidClass.phone == 'error' || _vm.companyManagerInfoValidClass.phone == 'empty'
						? 'invalid'
						: _vm.companyManagerInfoValidClass.phone == 'valid'
						? 'valid'
						: '',
				]},[_c('label',{staticClass:"label asterisk",attrs:{"for":""}},[_vm._v("휴대전화")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.phone),expression:"companyManagerInfo.phone"}],attrs:{"type":"text","placeholder":"휴대전화","value":"01099990000"},domProps:{"value":(_vm.companyManagerInfo.phone)},on:{"keyup":function($event){[
							_vm.checkPhone(_vm.companyManagerInfo.phone)
								? (_vm.companyManagerInfoValidClass.phone = 'valid')
								: (_vm.companyManagerInfoValidClass.phone = 'error'),
						]},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "phone", $event.target.value)}}}),(_vm.companyManagerInfoValidClass.phone == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 번호형식이 올바르지 않습니다. (-, +, 0~9) ")]):_vm._e(),(_vm.companyManagerInfoValidClass.phone == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 담당자 휴대전화는 필수 입력 항목입니다. ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }