<template>
	<div>
		<div class="container">
			<div class="body-contents fill-out">
				<h2 class="heading-1-b">다우오피스 멤버십 견적서 신청</h2>
				<div class="banner notice mb-20">
					<p class="body-2-r">
						상품 유형 및 부가 상품, 사용기간에 따라 이용 요금을 안내합니다.<br />
						이용 요금(견적서)은 입력하신 이메일로 전송됩니다.
					</p>
				</div>

				<div class="section-wrap gap64">
					<!--@--회사정보-->
					<company-basic-info
						:gatherFlag="save"
						v-on:gatherData="gatherData"
						:propsCompany="this.$store.getters.getCompanyInfo"
					></company-basic-info>

					<!--@--담당자 정보-->
					<company-manager-info
						:gatherFlag="save"
						v-on:gatherData="gatherData"
						:propsCompanyManager="this.$store.getters.getCompanyManagerInfo"
						:propsUserInfo="this.$store.state.login.currentUser"
					></company-manager-info>

					<!--@--서비스 약관 동의 -->
					<terms-agreement-info
						:type="'ESTIMATE'"
						:saveFlag="save"
						v-on:setTermsAgreement="setTermsAgreement"
					></terms-agreement-info>
					<step-footer :stepBtnInfo="stepBtnInfo" :actionBeforeMove="saveInput"></step-footer>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import StepFooter from '@/components/common/StepFooter.vue';
import CompanyBasicInfo from '@/components/apply/estimateInfo/CompanyBasicInfo.vue';
import CompanyManagerInfo from '@/components/apply/estimateInfo/CompanyManagerInfo.vue';
import TermsAgreementInfo from '@/components/apply/commonInfo/TermsAgreementInfo.vue';
import ToastComponent from '@/components/code/modal/ToastComponent.vue';

export default {
	data() {
		return {
			isOpenModal: false,
			validInfos: false,
			validTerms: false,
			toastOpen: false,
			toastText: '',
			saveFlag: false,
			save: false,
			stepBtnInfo: {
				prevLink: '/apply/step1',
				next: '견적서 받기',
			},
			input: {
				companyInfo: null,
				companyManagerInfo: null,
				companyInfoValidClass: null,
				companyManagerInfoValidClass: null,
				termsAgreementInfo: null,
			},
		};
	},
	watch: {
		async 'input.companyInfo'() {
			if (this.input.companyInfo && this.input.companyManagerInfo && this.input.termsAgreementInfo) {
				await this.dataValidation();
				await this.clearInput();
				this.save = !this.save;
			}
		},
		async 'input.companyManagerInfo'() {
			if (this.input.companyInfo && this.input.companyManagerInfo && this.input.termsAgreementInfo) {
				await this.dataValidation();
				await this.clearInput();
				this.save = !this.save;
			}
		},
		async 'input.termsAgreementInfo'() {
			if (this.input.companyInfo && this.input.companyManagerInfo && this.input.termsAgreementInfo) {
				await this.dataValidation();
				await this.clearInput();
				this.save = !this.save;
			}
		},
		saveFlag() {
			if (this.saveFlag) {
				this.$router.push('/apply/estimate/step2');
			}
		},
	},
	methods: {
		async saveInput() {
			this.save = true;
		},
		async dataValidation() {
			if (!this.save) return;
			// 회사정보
			this.save = false;
			const companyInfo = this.input.companyInfo;
			const companyInfoValidClass = this.input.companyInfoValidClass;
			const companyManagerInfo = this.input.companyManagerInfo;
			const companyManagerInfoValidClass = this.input.companyManagerInfoValidClass;
			const termsAgreementInfo = this.input.termsAgreementInfo;

			if (companyInfoValidClass.companyName !== 'valid') {
				return false;
			} else if (companyInfoValidClass.employeeCount !== 'valid') {
				return false;
			} else if (companyManagerInfoValidClass.name !== 'valid') {
				return false;
			} else if (companyManagerInfoValidClass.email !== 'valid') {
				return false;
			} else if (companyManagerInfoValidClass.phone !== 'valid') {
				return false;
			}
			// 약관 동의 체크
			for (let idx in termsAgreementInfo) {
				const terms = termsAgreementInfo[idx];
				if (terms.required && !terms.approved) {
					const prefix = Object.hasOwn(terms, 'customerPortal') ? terms.customerPortal + ' ' : '';
					const message = prefix + '[' + terms.termsName + '] 약관은 필수 동의 항목입니다.';

					this.openToast(message);
					return false;
				}
			}

			await this.$store.commit('SET_ESTIMATE_ALL_COMPANY_INFO', {
				companyInfo,
				companyManagerInfo,
			});
			await this.$store.commit('SET_ESTIMATE_TERMS_AGREEMENT_INFO', termsAgreementInfo);
			this.saveFlag = true;
			return true;
		},
		async clearInput() {
			for (const [key] of Object.entries(this.input)) {
				this.input[key] = null;
			}
		},
		async setTermsAgreement(termsAgreementInfo) {
			this.input.termsAgreementInfo = termsAgreementInfo;
		},
		async gatherData(data) {
			if (data.type == 'companyInfo') {
				this.input.companyInfo = data.companyInfo;
				this.input.companyInfoValidClass = data.companyInfoValidClass;
			} else if (data.type == 'companyManagerInfo') {
				this.input.companyManagerInfo = data.companyManagerInfo;
				this.input.companyManagerInfoValidClass = data.companyManagerInfoValidClass;
			}
		},
		openToast(text) {
			if (this.isOpenModal) {
				return;
			}
			this.isOpenModal = true;
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '800px',
					height: '80px',
					draggable: true,
				},
				{
					closed: this.closeModal,
				},
			);
		},
		closeModal() {
			this.isOpenModal = false;
		},
	},
	components: {
		CompanyBasicInfo,
		CompanyManagerInfo,
		TermsAgreementInfo,
		StepFooter,
	},
};
</script>

<style></style>
