<template>
	<div class="section">
		<h3 class="heading-3-b mb-20">담당자 정보</h3>
		<div class="contents group column gap20">
			<div class="group row col2 gap40 align-start">
				<div
					class="group-inp"
					:class="[
						companyManagerInfoValidClass.name == 'error' || companyManagerInfoValidClass.name == 'empty'
							? 'invalid'
							: companyManagerInfoValidClass.name == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<label for="" class="label asterisk"> 담당자명 </label>
					<input
						class=""
						type="text"
						placeholder="담당자명"
						v-model="companyManagerInfo.name"
						@input="
							[
								checkInputLength($event, 2, 64)
									? (companyManagerInfoValidClass.name = 'valid')
									: (companyManagerInfoValidClass.name = 'error'),
							]
						"
					/>
					<p v-if="companyManagerInfoValidClass.name == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						담당자명은 2자 이상, 64자 이하를 입력해야 합니다.
					</p>
					<p v-if="companyManagerInfoValidClass.name == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						담당자명은 필수 입력 항목입니다.
					</p>
				</div>
				<div
					class="group-inp"
					:class="[
						companyManagerInfoValidClass.email == 'error' || companyManagerInfoValidClass.email == 'empty'
							? 'invalid'
							: companyManagerInfoValidClass.email == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<label for="" class="label asterisk">이메일</label>
					<input
						class=""
						type="text"
						placeholder="이메일"
						v-model="companyManagerInfo.email"
						@keyup="
							[
								checkEmail(companyManagerInfo.email)
									? (companyManagerInfoValidClass.email = 'valid')
									: (companyManagerInfoValidClass.email = 'error'),
							]
						"
					/>
					<p v-if="companyManagerInfoValidClass.email == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						이메일 형식이 올바르지 않습니다.
					</p>
					<p v-if="companyManagerInfoValidClass.email == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						담당자 이메일은 필수 입력 항목입니다.
					</p>
				</div>
			</div>
			<div class="group row col1 half">
				<div
					class="group-inp"
					:class="[
						companyManagerInfoValidClass.phone == 'error' || companyManagerInfoValidClass.phone == 'empty'
							? 'invalid'
							: companyManagerInfoValidClass.phone == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<label for="" class="label asterisk">휴대전화</label>
					<input
						class=""
						type="text"
						placeholder="휴대전화"
						value="01099990000"
						v-model="companyManagerInfo.phone"
						@keyup="
							[
								checkPhone(companyManagerInfo.phone)
									? (companyManagerInfoValidClass.phone = 'valid')
									: (companyManagerInfoValidClass.phone = 'error'),
							]
						"
					/>
					<p v-if="companyManagerInfoValidClass.phone == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						번호형식이 올바르지 않습니다. (-, +, 0~9)
					</p>
					<p v-if="companyManagerInfoValidClass.phone == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						담당자 휴대전화는 필수 입력 항목입니다.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validation } from '@/components/apply/mixinsValidation.js';
export default {
	mixins: [validation],
	created() {
		this.companyManagerInfo.name =
			this.companyManagerInfo.name || this.propsCompanyManager?.name || this.propsUserInfo?.name;
		this.companyManagerInfo.email =
			this.companyManagerInfo.email || this.propsCompanyManager?.email || this.propsUserInfo?.email;
		this.companyManagerInfo.phone =
			this.companyManagerInfo.phone || this.propsCompanyManager?.phone || this.propsUserInfo?.phoneNumber;
	},
	data() {
		const origin = this.$store.getters.getEstimateAllCompanyInfo.companyManagerInfo;
		return {
			companyManagerInfo: {
				name: origin.name ? origin.name : '',
				email: origin.email ? origin.email : '',
				phone: origin.phone ? origin.phone : '',
			},
			companyManagerInfoValidClass: {
				name: origin.name ? 'valid' : '',
				email: origin.email ? 'valid' : '',
				phone: origin.phone ? 'valid' : '',
			},
		};
	},
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
		propsCompanyManager: {
			type: Object,
		},
		propsUserInfo: {
			type: Object,
		},
	},
	watch: {
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				await this.sendValidInfo();
			}
		},
	},
	methods: {
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companyManagerInfo)) {
				const key = Object.keys(this.companyManagerInfo)[idx];
				const value = this.companyManagerInfo[key];

				if (!value || value.length == 0) {
					this.companyManagerInfoValidClass[key] = 'empty';
					isAllValid = false;
				}
				if (this.companyManagerInfoValidClass[key] !== 'valid') {
					isAllValid = false;
				}
			}

			return isAllValid;
		},
		async sendValidInfo() {
			this.checkStrLength(this.companyManagerInfo.name, 2, 64)
				? (this.companyManagerInfoValidClass.name = 'valid')
				: (this.companyManagerInfoValidClass.name = 'error');

			this.checkEmail(this.companyManagerInfo.email)
				? (this.companyManagerInfoValidClass.email = 'valid')
				: (this.companyManagerInfoValidClass.email = 'error');

			this.checkPhone(this.companyManagerInfo.phone)
				? (this.companyManagerInfoValidClass.phone = 'valid')
				: (this.companyManagerInfoValidClass.phone = 'error');

			this.$emit('gatherData', {
				type: 'companyManagerInfo',
				companyManagerInfo: this.companyManagerInfo,
				companyManagerInfoValidClass: this.companyManagerInfoValidClass,
			});
		},
	},
};
</script>

<style></style>
