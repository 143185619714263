var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('h3',{staticClass:"heading-3-b mb-20"},[_vm._v("회사 정보")]),_c('div',{staticClass:"contents group column gap20"},[_c('div',{staticClass:"group row col2 gap40 align-start"},[_c('div',{staticClass:"group-inp",class:[
					_vm.companyInfoValidClass.companyName == 'error' || _vm.companyInfoValidClass.companyName == 'empty'
						? 'invalid'
						: _vm.companyInfoValidClass.companyName == 'valid'
						? 'valid'
						: '',
				]},[_c('label',{staticClass:"label asterisk",attrs:{"for":"target"}},[_vm._v("회사명")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.companyName),expression:"companyInfo.companyName"}],attrs:{"type":"text","placeholder":"회사명"},domProps:{"value":(_vm.companyInfo.companyName)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.companyInfo, "companyName", $event.target.value)},function($event){[
							_vm.checkInputLength($event, 2, 64)
								? (_vm.companyInfoValidClass.companyName = 'valid')
								: (_vm.companyInfoValidClass.companyName = 'error'),
						]}]}}),(_vm.companyInfoValidClass.companyName == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 회사명은 2자 이상, 64자 이하를 입력해야 합니다. ")]):_vm._e(),(_vm.companyInfoValidClass.companyName == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 회사명은 필수 입력 항목입니다. ")]):_vm._e()]),_c('div',{staticClass:"group-inp",class:[
					_vm.companyInfoValidClass.employeeCount == 'error' || _vm.companyInfoValidClass.employeeCount == 'empty'
						? 'invalid'
						: _vm.companyInfoValidClass.employeeCount == 'valid'
						? 'valid'
						: '',
				]},[_c('label',{staticClass:"label asterisk",attrs:{"for":"target"}},[_vm._v("직원수")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.employeeCount),expression:"companyInfo.employeeCount"},{name:"doms-inputValidate",rawName:"v-doms-inputValidate:onlyNumber",arg:"onlyNumber"}],attrs:{"type":"text","min":"0","placeholder":"직원수","id":"target"},domProps:{"value":(_vm.companyInfo.employeeCount)},on:{"keyup":function($event){[
							parseInt(_vm.companyInfo.employeeCount.length) < 1 || _vm.companyInfo.employeeCount <= 0
								? (_vm.companyInfoValidClass.employeeCount = 'error')
								: (_vm.companyInfoValidClass.employeeCount = 'valid'),
						]},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyInfo, "employeeCount", $event.target.value)}}}),(_vm.companyInfoValidClass.employeeCount == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 직원수를 입력해주세요. (0이상의 숫자) ")]):_vm._e(),(_vm.companyInfoValidClass.employeeCount == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 직원수는 필수 입력 항목입니다. ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }